@import url("./scrollbar.css");

/* Main */
html {
  font-size: 16px;
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  line-height: 0;
}

#root {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

$dark: #909090;
$darker: #545454;
$darkest: #212121;

/* MainInterface */
$colors: #03dac6, #fce938, #ec4e20, #0aff2f, #c71585, #ffa500, #ffa1ff, #764e1e,
  #275d4a, #1088ff, #00fa9a, #aa2091, #8c8c8c, #6b97b9, #a96452, #424c55;

.reql-variable {
  color: darken(nth($colors, 1), 10%);
  font-weight: bold;
}
.reql-operator {
  font-weight: bold;
  color: darken(nth($colors, 6), 10%);
}
.reql-escape {
  font-weight: bold;
  color: nth($colors, 3);
}
.reql-braces {
  font-weight: bold;
  color: darken(nth($colors, 7), 10%);
}
.reql-numeric-quantifier {
  font-weight: bold;
  color: nth($colors, 5);
}
.reql-anchor {
  font-weight: bold;
  color: darken(nth($colors, 4), 25%);
}

.match-table-cell-variable {
  font-weight: bolder !important;
  text-underline-offset: 4px;
  text-decoration: underline 4px;
}

.match-table-cell-group {
  white-space: break-spaces;
  word-break: break-all;
  word-wrap: break-word;
  .span {
    display: inline;
    margin-right: 0.5rem;
  }
  .group {
    display: inline;
  }
}

.match-table-char {
  &.match-table-newline {
    &::before {
      position: absolute;
      content: "↵";
      color: $dark;
    }
  }
  &.match-table-space {
    &::before {
      position: absolute;
      content: "•";
      color: $dark;
    }
  }
}

.cm-match {
  display: inline-block;
  border-radius: 4px;
  margin: 0 1px;
  .cm-match {
    margin: 4px 1px;
  }
}

@for $i from 0 to length($colors) {
  .cm-match-#{$i} {
    color: black;
    background-color: rgba(nth($colors, $i + 1), 66%);
    border: 2px solid nth($colors, $i + 1);
  }

  .match-table-cell-variable-#{$i} {
    text-decoration-color: nth($colors, $i + 1);
  }
}

a {
  text-decoration: none;
}

.MuiDataGrid-root {
  border: none !important;
  font-family: "Roboto Mono", monospace !important;

  .MuiDataGrid-cell {
    &:focus-within {
      outline: none !important;
    }
  }

  .MuiDataGrid-columnHeader {
    &:focus-within {
      outline: none !important;
    }
  }

  :not(.MuiDataGrid-index-column) > .MuiDataGrid-cellContent {
    border-left: 1px dotted $dark;
    border-right: 1px dotted $dark;
    padding: 0 4px;
    white-space: pre;
  }

  .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  .MuiDataGrid-index-column {
    color: $dark !important;
  }
}

.cm-reql-query-editor {
  .cm-editor {
    padding: 4px;
  }
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #a6a6a6;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}
::-webkit-scrollbar-thumb:active {
  background: #787878;
}
::-webkit-scrollbar-track {
  background: #212121;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #353535;
}
::-webkit-scrollbar-track:active {
  background: #424242;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
